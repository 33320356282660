$.extend( true, $.fn.dataTable.defaults, {
    "language": {
        "emptyTable": "Keine Daten in der Tabelle vorhanden",
        "info": "_START_ bis _END_ von _TOTAL_ Einträgen",
        "infoEmpty": "Keine Daten vorhanden",
        "infoFiltered": "(gefiltert von _MAX_ Einträgen)",
        "infoThousands": ".",
        "lengthMenu": "_MENU_ Einträge anzeigen",
        "loadingRecords": "Wird geladen ..",
        "processing": "Bitte warten ..",
        "paginate": {
            "first": "Erste",
            "previous": "Zurück",
            "next": "Nächste",
            "last": "Letzte"
        },
        "aria": {
            "sortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
            "sortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
        },
        "select": {
            "rows": {
                "_": "%d Zeilen ausgewählt",
                "1": "1 Zeile ausgewählt"
            },
            "1": "1 Eintrag ausgewählt",
            "2": "2 Einträge ausgewählt",
            "_": "%d Einträge ausgewählt",
            "cells": {
                "1": "1 Zelle ausgewählt",
                "_": "%d Zellen ausgewählt"
            },
            "columns": {
                "1": "1 Spalte ausgewählt",
                "_": "%d Spalten ausgewählt"
            }
        },
        "buttons": {
            "print": "Drucken",
            "copy": "Kopieren",
            "copyTitle": "In Zwischenablage kopieren",
            "copySuccess": {
                "_": "%d Zeilen kopiert",
                "1": "1 Zeile kopiert"
            },
            "collection": "Aktionen <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
            "colvis": "Spaltensichtbarkeit",
            "colvisRestore": "Sichtbarkeit wiederherstellen",
            "copyKeys": "Drücken Sie die Taste <i>ctrl<\/i> oder <i>⌘<\/i> + <i>C<\/i> um die Tabelle<br \/>in den Zwischenspeicher zu kopieren.<br \/><br \/>Um den Vorgang abzubrechen, klicken Sie die Nachricht an oder drücken Sie auf Escape.",
            "csv": "CSV",
            "excel": "Excel",
            "pageLength": {
                "-1": "Alle Zeilen anzeigen",
                "1": "Eine Zeile anzeigen",
                "_": "%d Zeilen anzeigen"
            },
            "pdf": "PDF"
        },
        "autoFill": {
            "cancel": "Abbrechen",
            "fill": "Alle Zellen mit <i>%d<i> füllen<\/i><\/i>",
            "fillHorizontal": "Alle horizontalen Zellen füllen",
            "fillVertical": "Alle vertikalen Zellen füllen",
            "info": "Bitte wählen Sie die gewünschte Aktion aus:"
        },
        "decimal": ",",
        "search": "Suche:",
        "searchBuilder": {
            "add": "Bedingung hinzufügen",
            "button": {
                "0": "Such-Baukasten",
                "_": "Such-Baukasten (%d)"
            },
            "clearAll": "Leeren",
            "condition": "Bedingung",
            "conditions": {
                "date": {
                    "after": "Nach",
                    "before": "Vor",
                    "between": "Zwischen",
                    "empty": "Leer",
                    "equals": "Entspricht",
                    "not": "Nicht",
                    "notBetween": "Nicht zwischen",
                    "notEmpty": "Nicht leer"
                },
                "moment": {
                    "after": "Nach",
                    "before": "Vor",
                    "between": "Zwischen",
                    "empty": "Leer",
                    "equals": "Entspricht",
                    "not": "Nicht",
                    "notBetween": "Nicht zwischen",
                    "notEmpty": "Nicht leer"
                },
                "number": {
                    "between": "Zwischen",
                    "empty": "Leer",
                    "equals": "Entspricht",
                    "gt": "Größer als",
                    "gte": "Größer als oder gleich",
                    "lt": "Kleiner als",
                    "lte": "Kleiner als oder gleich",
                    "not": "Nicht",
                    "notBetween": "Nicht zwischen",
                    "notEmpty": "Nicht leer"
                },
                "string": {
                    "contains": "Beinhaltet",
                    "empty": "Leer",
                    "endsWith": "Endet mit",
                    "equals": "Entspricht",
                    "not": "Nicht",
                    "notEmpty": "Nicht leer",
                    "startsWith": "Startet mit"
                },
                "array": {
                    "equals": "ist gleich",
                    "empty": "ist leer",
                    "contains": "enthält",
                    "not": "ist ungleich",
                    "notEmpty": "ist nicht leer",
                    "without": "aber nicht"
                }
            },
            "data": "Daten",
            "deleteTitle": "Filterregel entfernen",
            "leftTitle": "Äußere Kriterien",
            "logicAnd": "UND",
            "logicOr": "ODER",
            "rightTitle": "Innere Kriterien",
            "title": {
                "0": "Such-Baukasten",
                "_": "Such-Baukasten (%d)"
            },
            "value": "Wert"
        },
        "searchPanes": {
            "clearMessage": "Leeren",
            "collapse": {
                "0": "Suchmasken",
                "_": "Suchmasken (%d)"
            },
            "count": "{total}",
            "countFiltered": "{shown} ({total})",
            "emptyPanes": "Keine Suchmasken",
            "loadMessage": "Lade Suchmasken..",
            "title": "Aktive Filter: %d"
        },
        "searchPlaceholder": "Suchbegriff eingeben",
        "thousands": ".",
        "zeroRecords": "Keine passenden Einträge gefunden"
    }
});

$.fn.dataTable.Api.register('withSearch', function (searchContainer) {
    let dataTable = this;
    let debounce = 500;
    if (dataTable.data('no-debounce')) {
        debounce = 0;
    }
    searchContainer.on('keyup', $.debounce(function (e) {
        dataTable.search(this.value).draw();
    }, debounce));
});

$.fn.dataTable.Api.register('withFilter', function (filterContainer) {
    let selectedFilter = [];
    let dataTable = this;
    filterContainer.find("[data-filter-name]").on('change', function () {
        selectedFilter = [];

        filterContainer.find("[data-filter-name]").each(function (index, filter) {
            const filterElement = $(filter);

            // check here if filter is already applied and
            // append new value to already applied filter or create a new filter
            let filterItem = selectedFilter.find((filterItem) => filterItem.name === filterElement.data('filter-name'));

            if (filterElement.prop('checked')) {
                if (filterItem !== undefined) {
                    selectedFilter = selectedFilter.map((item) => {
                        if (item.name === filterElement.data('filter-name')) {
                            // Pipe is used to filter for more than one value per name - CD
                            item.value += '|' + filterElement.data('filter-value');
                        }

                        return item;
                    });
                } else {
                    if (filterElement.data('filter-value') !== undefined) {
                        selectedFilter.push({
                            name: filterElement.data('filter-name'),
                            value: filterElement.data('filter-value'),
                            column: filterElement.data('column-index') || filterElement.data('column-id'),
                        });
                    }
                }
            } else if(filterElement.find(':selected').length > 0) {
                // when it's a dropdown
                let selectedOption = filterElement.find(':selected');
                let filterItem = selectedFilter.find((filterItem) => filterItem.name === filterElement.data('filter-name'));

                if (filterItem !== undefined) {
                    selectedFilter = selectedFilter.map((item) => {
                        if (item.name === filterElement.data('filter-name')) {
                            // Pipe is used to filter for more than one value per name - CD
                            item.value += '|' + selectedOption.data('filter-value');
                        }

                        return item;
                    });
                } else {
                    if (selectedOption.data('filter-value') !== undefined) {
                        selectedFilter.push({
                            name: filterElement.data('filter-name'),
                            value: selectedOption.data('filter-value'),
                            column: filterElement.data('column-index') || filterElement.data('column-id'),
                        });
                    } else if (selectedOption.val() !== "") {
                        selectedFilter.push({
                            name: filterElement.data('filter-name'),
                            value: selectedOption.val(),
                            column: filterElement.data('column-index') || filterElement.data('column-id'),
                        });
                    }
                }
            }
        });

        // Reset filtering for all columns - CD
        dataTable.columns().search('');

        // Apply filter here - CD
        selectedFilter.forEach(function (item) {
            // use regex and disable smart search, we want to filter here! - CD
            dataTable.column(item.column).search('^' + item.value + '$', true, false);
        });

        dataTable.draw();
    });
});

window.initDatatable = (containerElement) => {
    const dataTableContainer = $(containerElement + ' #js-datatable');
    if (dataTableContainer.length > 0 && dataTableContainer.data('tableUseAjax') === undefined) {
        const withPagination = dataTableContainer.data('pagination');
        const pageLength = dataTableContainer.data('page-length') ?? 50;
        const orderIndex = dataTableContainer.data('order-index') ?? 0;
        const orderDirection = dataTableContainer.data('order-direction') ?? 'asc';
        const stateSave = dataTableContainer.data('state-save') ? true : false;
        const stateReset = dataTableContainer.data('state-reset') ? true : false;

        let rowReorder = false;

        if (dataTableContainer.data('reorder')) {
           if (dataTableContainer.data('reorder-data-src')) {
               rowReorder = {
                   dataSrc: 2
               }
           } else {
               rowReorder = true;
           }
        }

        const rowReorderData = rowReorder;

        let dataTable = dataTableContainer.DataTable({
            dom: 'tr<"bottom boxanize-pagination"' + (withPagination ? 'p' : '') + '>',
            pageLength: pageLength,
            stateSave: stateSave,
            stateSaveParams: function (settings, data) {
                  // data.search.search = "";
                  $('#js-datatable-search').val(data.search.search)
                $.each(data.columns, function (i, column) {
                    if(column.search.search) {
                        // alert(column.search.search);
                        let selectElem = $('#js-datatables-filter [data-column-index="' + i + '"]');
                        let search = column.search.search;
                        search = search.replace(/\^|\$/g, '');
                        selectElem.val(search);
                    }
                })

            },
            order: [orderIndex, orderDirection],
            rowReorder: rowReorderData,
            columnDefs: [
                { targets: 'no-sort', orderable: false },
                { targets: 'hide', visible: false},
                { targets: 'no-search', "searchable": false},
                {
                    targets: 'dtr-control',
                    orderable: false,
                }
            ],
            createdRow: function(row, data, rowIndex, columns) {
                const index = dataTableContainer.data('editableColumnIndex');
                if (index !== undefined) {
                    if (parseInt(data[index]) !== 1) {
                        $(row).css('cursor', 'not-allowed');
                        return;
                    }
                }

                $(row).on('click', function (e) {
                    if ($(e.target).hasClass('dtr-control')) {
                        return;
                    }

                    let linkTo = $(this).data('link-to');
                    let openAsModal = $(this).data('open-as-modal');
                    if (linkTo) {
                        if ( !openAsModal) {
                            window.location.href = linkTo;
                            return;
                        } else {
                            let editButton = $('<a></a>')
                                .data('header', $(this).data('modal-header'))
                                .data('size', 'md')
                                .data('content_url', linkTo)
                                .addClass('js-trigger-modal');
                            window.triggerBootstrapModal(editButton);
                        }
                    }
                });
            },
        });

        dataTable.withSearch($('#js-datatable-search'));
        dataTable.withFilter($('#js-datatables-filter'));

        if (dataTableContainer.data('reorder')) {
            dataTable.on('row-reorder', function ( e, diff, edit ) {
                // datatables positions starting at 0
                let changes = [];
                for (let key in diff) {
                    changes.push({
                        id: parseInt(dataTable.row(diff[key].node).data()[1]),
                        oldPosition: diff[key].oldPosition + 1,
                        newPosition: diff[key].newPosition + 1,
                    });
                }
                axios.put(dataTableContainer.data('reorder-route'), changes).then(() => {
                    $('[data-load-ajax-content]').trigger('needs-reload');
                });
            });
        }

       if (stateReset) {
           dataTable.state.clear();
           dataTable.search('')
           dataTable.columns().search('')
           dataTable.draw();
           // window.location.reload();
       }

        dataTable.on('draw.dt', function () {
            initPopover('#js-datatable');
        })
    }
}

$(document).ready(function () {
    initDatatable('body');
});

$(document).on('af.modal.shown', function () {
    initDatatable('#ajaxContentModal');
    initDatatable('#ajaxContentModalNoPadding');
})
