window.initFlatpickr = function (containerElem) {
    const FlatpickrGerman = require("flatpickr/dist/l10n/de.js").default.de;
    window.FlatpickrGerman = FlatpickrGerman;

    $(containerElem + ' [data-flatpickr="true"]').each(function (index, element) {
        const dateFormat = $(element).data('date-format') ?? 'd.m.Y';
        const minDate = $(element).data('min-date');
        const maxDate = $(element).data('max-date');
        const warningAfter = $(element).data('date-warning-after');
        const warningBefore = $(element).data('date-warning-before');
        const allowInput = $(element).data('allow-input') ?? false;
        const enableTime = $(element).data('enable-time') ?? false;

        $(element).flatpickr({
            locale: FlatpickrGerman,
            allowInput: allowInput,
            weekNumbers: true,
            altInput: true,
            altFormat: dateFormat,
            dateFormat: enableTime ? "Y-m-d H:i" : "Y-m-d",
            minDate: minDate ?? null,
            maxDate: maxDate ?? null,
            enableTime: enableTime,
            time_24hr: true,
            onDayCreate: function(dObj, dStr, fp, dayElem) {
                let localYear = dayElem.dateObj.getFullYear();
                let localMonth = '' + (dayElem.dateObj.getMonth() + 1);
                if (localMonth.length < 2) {
                    localMonth = '0' + localMonth;
                }
                let localDay = '' + dayElem.dateObj.getDate();
                if (localDay.length < 2) {
                    localDay = '0' + localDay;
                }
                let localDate = localYear + '-' + localMonth + '-' + localDay;

                if (warningAfter && (warningAfter < dayElem.dateObj.toISOString())) {
                    dayElem.classList.add('date-warning');
                }
                if (warningBefore && (warningBefore > localDate)) {
                    dayElem.classList.add('date-warning');
                }
            }
        });
    });

    $(containerElem + ' [data-flatpickr="time"]').each(function (index, element) {
        $(element).flatpickr({
            locale: FlatpickrGerman,
            noCalendar: true,
            enableTime: true,
            time_24hr: true,
            dateFormat: 'H:i',
        });
    });
}

$(document).ready(function () {
    initFlatpickr('body');
});

$(document).on('af.modal.shown', function () {
    initFlatpickr('#ajaxContentModal');
    initFlatpickr('#ajaxContentModalNoPadding');
});

