let autocompleteOpen = false;
let selectionStart = 0;
let selectedItem = 0;

const setCaretPosition = (element, caretPos) => {
    if (element[0].selectionStart) {
        element[0].focus();
        element[0].setSelectionRange(caretPos, caretPos);
    }
}

const performAutocompleteSearch = (searchTerm) => {
    $('#js-user-autocomplete').find('[data-username]').each(function (index) {
        let username = $(this).data('username');
        let term = searchTerm.toLowerCase().slice(1, searchTerm.length);
        if (username.indexOf(term.toLowerCase()) === -1) {
            $(this).hide();
        } else {
            $(this).show();
        }
    });

    const countOfVisibleItems = $('#js-user-autocomplete').find('[data-username]:visible').length;
    if (countOfVisibleItems <= 0) {
        $('#js-user-autocomplete').trigger('close-autocomplete');
    }
}

const getSelectedItem = (key, selectedItem) => {
    if (key === 'ArrowUp') {
        selectedItem--;

    } else if (key === 'ArrowDown') {
        selectedItem++;
    }

    if (selectedItem < 0) {
        selectedItem = 0;
    }

    if (selectedItem > ($('#js-user-autocomplete').find('[data-username]').length - 1)) {
        selectedItem = $('#js-user-autocomplete').find('[data-username]').length - 1;
    }

    return selectedItem;
}

const closeAutoComplete = () => {
    $('#js-user-autocomplete').find('[data-username]').off('click');
    $('#js-user-autocomplete').hide();
    $('#js-user-autocomplete').find('[data-username]').each(function () {
        $(this).show();
    });
    performSelectedIndexUpdate(0);
}

const openAutoComplete = (textArea) => {
    // set position of autocomplete box
    let paddingVertical = parseInt(textArea.css('padding-top').replace('px', '')) + parseInt(textArea.css('padding-bottom').replace('px', ''));
    let offsetTop = textArea.offset().top + textArea.height() + paddingVertical;
    let offsetLeft = textArea.offset().left;

    $('#js-user-autocomplete').on('close-autocomplete', function () {
        selectedItem = 0;
        autocompleteOpen = false;
        selectionStart = 0;
        closeAutoComplete();
    })

    $('#js-user-autocomplete').css('top', offsetTop + 'px');
    $('#js-user-autocomplete').css('left', offsetLeft + 'px');
    $('#js-user-autocomplete').show();
    $('#js-user-autocomplete').find('[data-username]').on('click', function (event) {
        const positions = getReplacePositions(textArea, event);
        if (positions) {
            const originalText = textArea.val();

            const partA = originalText.substring(0, positions.start === 0 ? positions.start : positions.start + 1);
            const partB = '@' + $(this).data('username');
            const partC = originalText.substring(positions.end, originalText.length);

            textArea.val(partA + partB + partC);

            setCaretPosition(textArea, (positions.start === 0 ? positions.start : positions.start + 1) + partB.length);
        }

        $('#js-user-autocomplete').trigger('close-autocomplete');
    });

    $('#js-user-autocomplete-close-button').one('click', function () {
        $('#js-user-autocomplete').trigger('close-autocomplete');
    });

    // always select first item
    performSelectedIndexUpdate(0);
}

const performSelectedIndexUpdate = (selectedItem) => {
    $('#js-user-autocomplete').find('[data-username]:visible').each(function (index) {
        if (index === selectedItem && $(this).css('display') !== 'none') {
            $(this).css('border', 'thin solid #005C8A');
            $(this).css('padding', '0 2px');
            $(this).css('border-radius', '5px');
        } else {
            $(this).css('padding', '0');
            $(this).css('border', 'none');
        }
    });
}

const getCurrentWritingWord = (textArea, event) => {
    const textValue = textArea.val();
    const caretPosition = event.target.selectionStart;

    if (caretPosition < textValue.length) {
        const end = textValue.indexOf(' ', caretPosition);
        const slicedText = textValue.slice(0, end);
        const lastSpace = slicedText.lastIndexOf(' ');

        return textValue.slice(lastSpace === -1 ? 0 : lastSpace, end).trim();
    } else if (caretPosition === textValue.length) {
        const lastSpace = textValue.lastIndexOf(' ');
        return textValue.slice(lastSpace === -1 ? 0 : lastSpace, caretPosition).trim();
    }
}

const getReplacePositions = (textArea, event) => {
    console.log(event.target.selectionStart, selectionStart);
    const textValue = textArea.val();
    const caretPosition = event.target.selectionStart ?? selectionStart;

    if (caretPosition < textValue.length) {
        const end = textValue.indexOf(' ', caretPosition);
        const slicedText = textValue.slice(0, end);
        const lastSpace = slicedText.lastIndexOf(' ');

        return { 'start': lastSpace === -1 ? 0 : lastSpace, 'end': end};
    } else if (caretPosition === textValue.length) {
        const lastSpace = textValue.lastIndexOf(' ');
        return { 'start': lastSpace === -1 ? 0 : lastSpace, 'end': caretPosition};
    }
}

const registerKeyUpEvent = (element, event) => {
    let textArea = element;
    selectionStart = textArea[0].selectionStart;
    // The keys in that array are triggered in keydown to prevent something before it was write down.
    // So we need to ignore them here.
    if (['Escape', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].indexOf(event.key) === -1) {
        const currentWritingWord = getCurrentWritingWord(textArea, event);
        if (currentWritingWord.indexOf('@') === 0 && ! autocompleteOpen) {
            autocompleteOpen = true;
            openAutoComplete(textArea)
        }

        if (autocompleteOpen) {
            performAutocompleteSearch(
                getCurrentWritingWord(element, event)
            );
            performSelectedIndexUpdate(0);
        }
    }
}

const registerKeyDownEvent = (event) => {
    if (event.key === 'Escape') {
        event.preventDefault();
        $('#js-user-autocomplete').trigger('close-autocomplete');
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        selectedItem = getSelectedItem(event.key, selectedItem);
        performSelectedIndexUpdate(selectedItem);
    } else if (event.key === 'Enter' && autocompleteOpen) {
        event.preventDefault();
        $('#js-user-autocomplete').find('[data-username]:visible')[selectedItem].click();
        $('#js-user-autocomplete').trigger('close-autocomplete');
    } else if (event.key === ' ') { // Space
        $('#js-user-autocomplete').trigger('close-autocomplete');
    }
}

const registerUserAutocomplete = (element) => {
    element.off('keyup');
    element.on('keyup', function (e) {
        registerKeyUpEvent($(this), e);
    });

    element.off('keydown');
    element.on('keydown', function (e) {
        registerKeyDownEvent(e);
    });

    element.closest('form').find('[type="submit"]').on('click', function () {
        $('#js-user-autocomplete').trigger('close-autocomplete');
    });
}

export default registerUserAutocomplete;
