$(function () {
    let bodyElement = $('body');

    bodyElement.on('click', '.js-editable-card .js-editable-card-content', function () {
        const editableCard = $(this).closest('.js-editable-card');
        const editableCardContent = $(this);
        const canEdit = editableCard.data('canEdit') ?? false;

        if (canEdit && ! editableCardContent.hasClass('opened')) {
            editableCardContent.addClass('loading');
            editableCardContent.html('');
            editableCardContent.animate({
                height: getInitialEditableCardHeight() + 'px',
            }, 1000, function () {
                $(editableCardContent).removeClass('loading');
            });

            loadEditForm(editableCardContent, {
                editUrl: editableCard.data('edit-url'),
                showUrl: editableCard.data('show-url'),
                editableCardId: editableCard.data('editable-card-id'),
            })
        } else if(!canEdit && ! editableCardContent.hasClass('opened')) {
            editableCardContent.addClass('loading');
            editableCardContent.html('');
            editableCardContent.animate({
                height: '100%',
            }, 1000, function () {
                $(editableCardContent).removeClass('loading');
            });

            loadReadonlyView(editableCardContent, {
                readonlyUrl: editableCard.data('readonly-url'),
                showUrl: editableCard.data('show-url'),
                editableCardId: editableCard.data('editable-card-id'),
            })
        }
    });
})
