const buildCompanyRouteForSelect2 = (companyId, personId, projectId) => {
    let route = `/ajax/select2/unternehmen`;

    if (personId !== null && projectId === null) {
        route = route + `?person_id=${personId}`;
    } else if (personId === null && projectId !== null) {
        route = route + `?project_id=${projectId}`;
    } else if (personId !== null && projectId !== null) {
        route = route + `?person_id=${personId}&project_id=${projectId}`;
    }

    return route;
}

const buildPersonRouteForSelect2 = (companyId, personId, projectId) => {
    let route = `/ajax/select2/personen`;

    if (companyId !== null && projectId === null) {
        route = route + `?company_id=${companyId}`;
    } else if (companyId === null && projectId !== null) {
        route = route + `?project_id=${projectId}`;
    } else if (companyId !== null && projectId !== null) {
        route = route + `?company_id=${companyId}&project_id=${projectId}`;
    }

    return route;
}

const buildProjectRouteForSelect2 = (companyId, personId) => {
    let route = `/ajax/select2/projekte`;

    if (companyId !== null && personId === null) {
        route = route + `?company_id=${companyId}`;
    } else if (companyId === null && personId !== null) {
        route = route + `?person_id=${personId}`;
    } else if (companyId !== null && personId !== null) {
        route = route + `?company_id=${companyId}&person_id=${personId}`;
    }

    return route;
}

const buildRoutesForSelect2 = (companyId, personId, projectId) => {
    const companyRoute = buildCompanyRouteForSelect2(companyId, personId, projectId);
    const personRoute = buildPersonRouteForSelect2(companyId, personId, projectId);
    const projectRoute = buildProjectRouteForSelect2(companyId, personId);

    return { companyRoute, personRoute, projectRoute };
}

window.initialSelect2Registration = (cardTaskContainer, taskId) => {
    let select2CompanyElement = cardTaskContainer.find(`#js-select2-company-${taskId}`);
    let select2PersonElement = cardTaskContainer.find(`#js-select2-person-${taskId}`);
    let select2ProjectElement = cardTaskContainer.find(`#js-select2-project-${taskId}`);

    const companyId = parseToInt(select2CompanyElement.val());
    const personId = parseToInt(select2PersonElement.val());
    const projectId = parseToInt(select2ProjectElement.val());

    const routes = buildRoutesForSelect2(companyId, personId, projectId);
    select2CompanyElement.data('url', routes.companyRoute);
    select2PersonElement.data('url', routes.personRoute);
    select2ProjectElement.data('url', routes.projectRoute);

    initSelect2Ajax(`#js-select2-company-container-${taskId}`);
    initSelect2Ajax(`#js-select2-person-container-${taskId}`);
    initSelect2Ajax(`#js-select2-project-container-${taskId}`);
}

window.registerCompanySelect2 = (cardTaskContainer, taskId) => {
    cardTaskContainer.find(`#js-select2-company-${taskId}`).on('select2:select', function () {
        initialSelect2Registration(cardTaskContainer, taskId);
    });
}

window.registerPersonSelect2 = (cardTaskContainer, taskId) => {
    cardTaskContainer.find(`#js-select2-person-${taskId}`).on('select2:select', function () {
        initialSelect2Registration(cardTaskContainer, taskId);
    });
}

window.registerProjectSelect2 = (cardTaskContainer, taskId) => {
    cardTaskContainer.find(`#js-select2-project-${taskId}`).on('select2:select', function () {
        initialSelect2Registration(cardTaskContainer, taskId);
    });
}
