import registerUserAutocomplete from "./user-autocomplete";

const reloadCommentList = (taskId) => {
    const taskList = $('#js-task-comment-list-' + taskId);
    if (taskList.length > 0) {
        taskList.load(taskList.data('route'), function (response) {
            initToolTip('#js-task-comment-list-' + taskId);

            $(taskList).closest('.js-editable-card-content').animate({
                height: '100%',
            });

            $('.js-task-comment-content').off('input');
            $('.js-task-comment-content').on('input', function () {
                $(this).closest('.task-comment').addClass('task-comment-editmode');
                $(this).parent().find('.js-task-comment-save').show();
            });

            registerUserAutocomplete($('.js-task-comment-content'));

            $('.js-task-comment-edit').on('click', function () {
                const taskCommentContainer = $(this).closest('.task-comment');
                $(this).hide();
                taskCommentContainer.find('.js-task-comment-save').show();
                taskCommentContainer.find('.js-task-comment-content').show();
                taskCommentContainer.find('.js-task-comment-cancel').show();
                taskCommentContainer.find('.js-task-comment-content-formatted').hide();
                taskCommentContainer.find('.js-task-comment-delete').hide();
            });

            $('.js-task-comment-cancel').on('click', function () {
                const taskCommentContainer = $(this).closest('.task-comment');
                $(this).hide();
                taskCommentContainer.find('.js-task-comment-save').hide();
                taskCommentContainer.find('.js-task-comment-content').hide();
                taskCommentContainer.find('.js-task-comment-edit').show();
                taskCommentContainer.find('.js-task-comment-content-formatted').show();
                taskCommentContainer.find('.js-task-comment-delete').show();

                taskCommentContainer.find('.js-task-comment-content')
                    .val(taskCommentContainer.find('.js-task-comment-content').data('initial-content'));
            });

            $('.js-task-comment-save').on('click', function () {
                const contentBox = $(this).closest('.task-comment').find('.js-task-comment-content');
                const value = contentBox.val();

                axios.put(contentBox.data('editRoute'), {
                    comment: value,
                }).then((response) => {
                    if (response.status === 200) {
                        $.toast({
                            icon: 'success',
                            text: response.data.success
                        });
                        $(window).trigger('reload-comment-list');
                    }
                });
            });
        });
    }
}

$(function () {
    $(window).on('load-task-comments', function (e, taskId) {
        initAjaxForm('#js-task-comment-list-form-' + taskId);
        reloadCommentList(taskId);

        $(window).on('reload-comment-list', function () {
            $('#js-comment-textarea-' + taskId).val('');
            reloadCommentList(taskId);
        });
    });
})
