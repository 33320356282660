require('./bootstrap');

require('./addCompanyItem');
require('./autonumeric');
require('./colorpicker');
require('./companyCreation');
require('./datatable');
require('./dialog');
require('./dropzone');
require('./flatpickr');
require('./forms');
require('./globalSearch');
require('./sectionItems');
require('./select2');
require('./sortable');
require('./tooltip');
require('./popover');
require('./utilities');

require('./components/company-item-card');
require('./components/checklist');
require('./components/editable-card-functions');
require('./components/editable-card');
require('./components/task-card');
require('./components/context-selector');
require('./components/office-document-template');
require('./components/task-comments');
require('./components/task-description');
require('./components/marketing');



(function($) {
    $.fn.extend({
        triggerAll: function (events, params) {
            var el = this, i, evts = events.split(' ');
            for (i = 0; i < evts.length; i += 1) {
                el.trigger(evts[i], params);
            }
            return el;
        }
    });
})(jQuery);

$(function () {
    $('.filter-user-settings').on('change', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');
        let $filterGroup = $(this).data('user-setting-filter-group');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'filter',
            value: $(this).val(),
            group: $filterGroup,
        }).then(function() {
            // do nothing for now...
        });
    });

    $('.checkbox-user-settings').on('change', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'checkbox',
            checked: $(this).is(':checked')
        }).then(function() {
            // do nothing for now...
        });
    });

    $('.js-mobile-collapsible').on('click', function() {
        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'checkbox',
            checked: ! $(this).hasClass('js-collapsible-selected'),
        }).then(function() {
            // do nothing for now...
        });
    });

    // automatically save user settings for collapsible items
    $('.collapse').on('shown.bs.collapse hidden.bs.collapse', function(e) {

        let $save_url = window.CRM.save_url;
        let $id = $(this).attr('id');

        if (!$save_url) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'open_tabs',
            event: e.type
        })
            .then(function() {
                // do nothing for now.
            });
    })

    $('.active-user-settings').on('click', function() {

        let $save_url = window.CRM.save_url;
        if (!$save_url) {
            return;
        }

        let $id = $(this).attr('id');
        let $group = $(this).data('group');

        if (!$group) {
            return;
        }

        axios.put($save_url,  {
            id: $id,
            type: 'active',
            group: $group,
            active: true
        })
            .then(function() {
                // do nothing for now.
            });
    });


    try {
        if ($('#taskUsersContainer').length > 0) {
            window.psTaskUsers = new PerfectScrollbar('#taskUsersContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }

    try {
        if($('#taskCategoriesContainer').length > 0) {
            window.psTaskCategories = new PerfectScrollbar('#taskCategoriesContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }

    try {
        if ($('#taskCompaniesContainer').length > 0) {
            window.psTaskCompanies = new PerfectScrollbar('#taskCompaniesContainer', {
                wheelSpeed: 2,
                wheelPropagation: false,
                minScrollbarLength: 20
            });
        }
    } catch (ex) {
        console.log(ex);
    }
})
