$(function () {
    const companyEngine = new Bloodhound({
        remote: {
            url: "/typeahead/search?q=%QUERY%",
            wildcard: '%QUERY%',
            cache: false,
            transform: function(d) {
                return d.companies;
            }
        },
        datumTokenizer: Bloodhound.tokenizers.whitespace('q'),
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });
    const peopleEngine = new Bloodhound({
        remote: {
            url: "/typeahead/search?q=%QUERY%",
            wildcard: '%QUERY%',
            cache: false,
            transform: function(d) {
                return d.people;
            }
        },
        datumTokenizer: Bloodhound.tokenizers.whitespace('q'),
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });
    const projectsEngine = new Bloodhound({
        remote: {
            url: "/typeahead/search?q=%QUERY%",
            wildcard: '%QUERY%',
            cache: false,
            transform: function(d) {
                return d.projects;
            }
        },
        datumTokenizer: Bloodhound.tokenizers.whitespace('q'),
        queryTokenizer: Bloodhound.tokenizers.whitespace
    });

    const companySearchHeader = (suggestions, query) => '<div class="tt-search-header">' +
        '   <span class="tt-header">Unternehmen</span>' +
        '   <span class="tt-border"><hr/></span>' +
        (suggestions.length > 0 ? '<span class="tt-found-count" data-type="company" data-searchterm="' + query + '">' + suggestions.length +' Treffer</span>' : '') +
        '</div>';
    const personSearchHeader = (suggestions, query) => '<div class="tt-search-header">' +
        '   <span class="tt-header">Personen</span>' +
        '   <span class="tt-border"><hr/></span>' +
        (suggestions.length > 0 ? '<span class="tt-found-count" data-type="person" data-searchterm="' + query + '">' + suggestions.length +' Treffer</span>' : '') +
        '</div>';
    const projectSearchHeader = (suggestions, query) => '<div class="tt-search-header">' +
        '   <span class="tt-header">Projekte</span>' +
        '   <span class="tt-border"><hr/></span>' +
        (suggestions.length > 0 ? '<span class="tt-found-count" data-type="project" data-searchterm="' + query + '">' + suggestions.length +' Treffer</span>' : '') +
        '</div>';
    let globalSearchElement = $('#js-global-search');
    globalSearchElement.typeahead(
        {
            highlight: true,
            minLength: 3,
            limit: 10,
        },
        {
            limit: 10,
            source: companyEngine,
            name: "companies",
            displayKey: 'name',
            templates: {
                empty: function (context) {
                    return companySearchHeader(context) + '<div class="tt-content">Keine Treffer</div>';
                },
                header: (context) => companySearchHeader(context.suggestions, context.query),
                suggestion: function (context) {

                    let output = [];
                    output.push('<div class="tt-content">');
                    output.push(_.escape(context.name));
                    if (context.customer_number) {
                        output.push('<br />');
                        output.push('<small>');
                        output.push('NR: ' + _.escape(context.customer_number));
                        output.push('</small>');
                    }
                    output.push('</div>');

                    return output.join('');
                },
            }
        },
        {
            limit: 10,
            source: peopleEngine,
            name: 'people',
            displayKey: 'full_name',
            templates: {
                empty: function (context) {
                    return personSearchHeader(context) + '<div class="tt-content">Keine Treffer</div>';
                },
                suggestion: function (context) {
                    let output = [];
                    output.push('<div class="tt-content">');
                    output.push(_.escape(context.full_name));
                    if (context.company_name || context.person_number) {
                        output.push('<br />');
                        output.push('<small>');

                        if (context.company_name) {
                            output.push(_.escape(context.company_name))
                            output.push(_.escape(context.person_number) ? ', ' : ' ')
                        }

                        if (context.person_number) {
                            output.push('NR: ' + _.escape(context.person_number));
                        }

                        output.push('</small>');
                    }
                    output.push('</div>');

                    return output.join('');
                },
                header: (context) => personSearchHeader(context.suggestions, context.query),
            }
        },
        {
            limit: 10,
            source: projectsEngine,
            name: 'projects',
            displayKey: 'name',
            templates: {
                empty: function (context) {
                    return projectSearchHeader(context) + '<div class="tt-content">Keine Treffer</div>';
                },
                suggestion: function (context) {
                    let output = [];
                    output.push('<div class="tt-content">');
                    output.push(context.name);

                    if (context.company_name || context.project_number) {
                        output.push('<br />');
                        output.push('<small>');

                        if (context.company_name) {
                            output.push(_.escape(context.company_name))
                            output.push(_.escape(context.project_number) ? ', ' : ' ')
                        }

                        if (context.project_number) {
                            output.push('NR: ' + _.escape(context.project_number));
                        }

                        output.push('</small>');
                    }

                    output.push('</div>');

                    return output.join('');
                },
                header: (context) => projectSearchHeader(context.suggestions, context.query),
            }
        }
    );

    globalSearchElement.on('typeahead:select', function(evt, item) {
        const type = item._type;

        switch (type) {
            case 'company':
                window.location = '/unternehmen/' + item.id
                break;
            case 'person':
                window.location = '/personen/' + item.id
                break;
            case 'project':
                window.location = '/projekte/' + item.id
                break;
        }
    })

    $('body').on('click', '.tt-found-count', function () {
        const searchTerm = $(this).data('searchterm');
        const type = $(this).data('type');

        let route;
        switch (type) {
            case 'company':
                route = '/unternehmen';
                break;
            case 'person':
                route = '/personen';
                break;
            case 'project':
                route = '/projekte';
                break;
        }

        route = route + '?query=' + searchTerm;
        location.href = route;
        return;
    })
});
