$(document).ready(function() {


    $('body').on('click', '.js-add-section-btn', function (e) {
        e.preventDefault()
        let url = $(this).data('url');

        let data = {
            position: $(this).data('position'),
            type: $(this).data('type'),
        }

        axios.post(url, data).then(function () {
            window.location.reload();
        }).catch(__defaultAxiosFailFunction);
    })

    $('.office-document-position-table .js-edit-quantity').on('click', function (e) {
        e.preventDefault();
        let quantityElem = $(this).parent().find('input')[0];
        let quantity = AutoNumeric.getAutoNumericElement(quantityElem).getNumericString();

        let url = $(this).data('url');

        axios.patch(url, {quantity:quantity}).then(function e() {
            window.location.reload();
        }).catch(__defaultAxiosFailFunction)
    })

    $('.office-document-section-content-container .js-section-edit-content').on('click', function (e) {

        e.preventDefault();

        let url = $(this).data('url');
        let content = $(this).closest('.office-document-section-content-container').find('input').val();

        axios.patch(url, {content:content}).then(function e() {
            window.location.reload();
        }).catch(__defaultAxiosFailFunction)
    })

    $('.js-update-office-document-sub-total').on('click', function (e) {
        e.preventDefault();
        let elemPriceNetto = $(this).closest('.office-document-sub-total-container').find('input[name="sub_total_price_netto"]')[0];
        let elemTax = $(this).closest('.office-document-sub-total-container').find('input[name="sub_total_tax"]')[0];
        let priceNetto = AutoNumeric.getAutoNumericElement(elemPriceNetto).getNumericString();
        let tax = AutoNumeric.getAutoNumericElement(elemTax).getNumericString();

        let url = $(this).data('url');

        axios.patch(url, {sub_total_price_netto: priceNetto, sub_total_tax: tax}).then(function () {
            window.location.reload()
        }).catch(__defaultAxiosFailFunction)
    })

    $('.js-hide-single-prices').on('change', function () {
        let url = $(this).data('url');
        let data = {
            sub_hide_single_prices: $(this).is(':checked')
        }

        axios.patch(url, data).then(function () {
            $.toast({
                icon: 'success',
                text: 'Die Zwischensumme wurde aktualisiert',
            })
        })
    })
})
