import registerUserAutocomplete from "./components/user-autocomplete";

$(document).on('click', '.addItemContainer a', function(e) {
    let $addItemGlobalContainer = $(this).closest('.addItemGlobalContainer');

    $addItemGlobalContainer.find('.addItemContainer a').removeClass('active');
    $(this).addClass('active');
    e.preventDefault();
    $addItemGlobalContainer.find('.addItemArea').attr('placeholder', $(this).data('placeholder'))
    $addItemGlobalContainer.find('[name="type"]').val($(this).data('type'));

    if ($(this).data('type') === 'task') {
        $addItemGlobalContainer.find('.addItemTextContainer').hide();
        $addItemGlobalContainer.find('.addItemArea').prop('required', false);
        $addItemGlobalContainer.find('.item_is_task').prop('checked', true).prop('disabled', true).trigger('change');
    } else {
        $addItemGlobalContainer.find('.addItemTextContainer').show();
        $addItemGlobalContainer.find('.addItemArea').prop('required', true);
        $addItemGlobalContainer.find('.item_is_task').prop('disabled', false);
    }
})

$(document).on('change', '.item_is_task', function() {
    let $addItemGlobalContainer = $(this).closest('.addItemGlobalContainer');

    if ($(this).is(':checked')) {
        let cardTaskContainer = $addItemGlobalContainer.find('.visible-item-task');
        cardTaskContainer.show({
            complete: function () {
                registerUserAutocomplete($addItemGlobalContainer.find('.item_task_description'));
            }
        });
        $addItemGlobalContainer.find('.item_task_description').trigger('change');

        initialSelect2Registration(cardTaskContainer, 'creation');
        registerCompanySelect2(cardTaskContainer, 'creation');
        registerPersonSelect2(cardTaskContainer, 'creation');
        registerProjectSelect2(cardTaskContainer, 'creation');
    } else {
        $addItemGlobalContainer.find('.visible-item-task').hide();
        $addItemGlobalContainer.find('.item_task_description').val('');
        $addItemGlobalContainer.find('.addItemArea').trigger('change');
    }
})
$('.item_is_task').trigger('change');

$(document).on('change keyup', '.addItemArea, .item_task_description', function() {
    let $addItemGlobalContainer = $(this).closest('.addItemGlobalContainer');

    let addItemContentDisabled = $addItemGlobalContainer.find('.addItemArea').is(':visible')
        && $addItemGlobalContainer.find('.addItemArea').val().length === 0;
    let addItemTaskDisabled = false;

    if ($addItemGlobalContainer.find('.item_is_task').is(':checked')) {
        addItemTaskDisabled = $addItemGlobalContainer.find('.item_task_description').val().length === 0;
    }
    $addItemGlobalContainer.find('.addItemSubmitButton').prop('disabled', addItemContentDisabled || addItemTaskDisabled);
})

$(document).on('submit', '#js-company-item-creation-form', function () {
    let $addItemGlobalContainer = $(this).closest('.addItemGlobalContainer');
    $addItemGlobalContainer.find('.addItemSubmitButton').prop('disabled', true);
})

$(window).on('enable-add-company-item-button', function () {
    $('.addItemGlobalContainer').find('.addItemSubmitButton').prop('disabled', false);
});
