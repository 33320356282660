window.initSortable = (containerElement) => {
    $(containerElement + ' [data-is-sortable]').each(function (index, element) {
        Sortable.create(element, {
            draggable: '.list-group-item',
            preventOnFilter: false,
            filter: "input",
            onUpdate: function (event) {
                const items = $(event.from).children();
                let newOrder = [];
                items.each(function (index, item) {
                    newOrder.push({
                        id: $(item).data('id'),
                        position: index + 1,
                    })
                });

                const route = $(element).data('route');
                axios.post(route, {
                    newOrder
                });
            }
        });
    })
}

$(function () {
    initSortable('body');
});

$(document).on('af.modal.shown', function () {
    initSortable('#ajaxContentModal');
    initSortable('#ajaxContentModalNoPadding');
});
