Dropzone.autoDiscover = false;
const dropzoneLang = {
    dictDefaultMessage: 'Legen Sie Dateien hier ab um sie hochzuladen',
    dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Datei-Uploads nicht',
    dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
    dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
    dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
    dictResponseError: 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
    dictCancelUpload: 'Hochladen abbrechen',
    dictRemoveFile: 'Datei entfernen',
    dictMaxFilesExceeded: 'Sie können keine weiteren Dateien mehr hochladen',
};

window.initDropzone = (containerElement) => {
    $(containerElement + ' .js-document-dropzone').each(function (index, element) {
        $(element).dropzone({
            url: $(element).closest('form').attr('action'),
            parallelUploads: 1,
            maxFiles: 1,
            autoProcessQueue: false,
            acceptedFiles: 'application/pdf,image/jpeg,image/png,image/gif,.xls,.xlsx,.doc,.docx',
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            ...dropzoneLang,
            init: function() {
                let documentDropzone = this;

                $(element).closest('form').on('submit', function(e) {
                    e.preventDefault();
                    documentDropzone.processQueue();
                    return false;
                });

                this.on("sending", function(data, xhr, formData) {
                    $(element).closest('form').find("[type='text'],select").each(function(index, innerElement) {
                        formData.append($(innerElement).attr('name'), $(innerElement).val());
                    });
                });
            },
            error: function(file, response) {
                if (response.errors) {
                    const errors = _.join(_.flatten(_.map(response.errors, (item) => item)), '<br/>');

                    $.toast({
                        text: errors,
                        bgColor : '#DA343F',
                        textColor : '#ffffff',
                        allowToastClose : true,
                        hideAfter : false,
                    });

                    // reset dropzone file status
                    this.removeAllFiles(true);
                    file.status = undefined;
                    file.accepted = undefined;
                    this.addFile(file)
                }
            },
            complete: function(response) {
                if (response.status !== "error" && response.status !== "queued") {
                    window.location.reload();
                }
            }
        });
    });
}

$(document).ready(function() {
    initDropzone('body');
});

$(document).on('af.modal.shown', function () {
    initDropzone('#ajaxContentModal');
    initDropzone('#ajaxContentModalNoPadding');
});
