window.initPopover = (container) => {
    $(container + ' [data-bs-toggle="popover"]').each(function (index, element) {

        options = {
            sanitize: false,
            html: true,
        }

        let content = $(this).data('content');

        if (content) {
            options.content = $(content).html();
        }

        new bootstrap.Popover(element, options)
    });
}

$(function () {
    initPopover('body');
});
