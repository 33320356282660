window.initCompanyCreation = () => {
    $('.js-company-creation').on('keydown', $.debounce(function (e) {
        const value = $(this).val();
        axios.get('/ajax/is-company-name-taken?company_name=' + value).then((response) => {
            if (response.data.success) {
                $('#js-company-creation-validity').fadeIn();
            } else {
                $('#js-company-creation-validity').fadeOut();
            }
        })
    }, 500));
}


$(document).ready(function() {
    initCompanyCreation('body');
});

$(document).on('af.modal.shown', function () {
    initCompanyCreation('#ajaxContentModal');
});
