window.getInitialEditableCardHeight = () => {
    let initialHeight = 1250;
    let bodyElement = $('body');
    if (bodyElement.hasClass('desktop')) {
        initialHeight = 770;
    } else if (bodyElement.hasClass('tablet')) {
        initialHeight = 850;
    } else if (bodyElement.hasClass('mobile')) {
        initialHeight = 1250;
    }

    return initialHeight;
}

window.loadReadonlyView = (editableCardContent, options) => {
    editableCardContent.load(options.readonlyUrl, function () {
        $(editableCardContent).addClass('opened');
        const editableCardId = options.editableCardId;
        registerCancelButton(editableCardContent, options);

        $(window).trigger('load-task-comments', editableCardId);
        $(window).trigger('after-load-editable-card', editableCardId);
    });
};

window.loadEditForm = (editableCardContent, options) => {
    editableCardContent.load(options.editUrl, function () {
        $(editableCardContent).addClass('opened');
        const editableCardId = options.editableCardId;

        reinitFields(editableCardId);

        registerTaskCreateButton(editableCardContent, options);
        registerDeleteButton(editableCardContent);
        registerCancelButton(editableCardContent, options);
        registerSubmitButton(editableCardContent, options);

        initialSelect2Registration(editableCardContent, editableCardId);
        registerCompanySelect2(editableCardContent, editableCardId);
        registerPersonSelect2(editableCardContent, editableCardId);
        registerProjectSelect2(editableCardContent, editableCardId);

        const initialHeight = editableCardContent.prop('scrollHeight');
        registerDynamicHeight('#js-editable-card-description-' + editableCardId);
        resizeEditCardOnTyping(editableCardContent, initialHeight);

        $(window).trigger('load-task-comments', editableCardId);
        $(window).trigger('after-load-editable-card', editableCardId);
    })
}

window.registerSubmitButton = (editableCardContent, options) => {
    editableCardContent.find('.js-editable-card-submit').on('click', function (e) {
        e.preventDefault();

        let form = $(this).closest('form');
        const formData = form.serialize();

        axios.request({
            url: form.attr('action'),
            data: formData,
            method: 'PUT',
        }).then(response => {
            if (response.status === 200) {
                handleSubmitResponse(response, editableCardContent, options);
            } else {
                window.__defaultAxiosFailFunction({ response, message: 'Etwas ist schief gelaufen.' });
            }
        }).catch(__defaultAxiosFailFunction)
    });
}

window.handleSubmitResponse = (response, editableCardContent, options) => {
    editableCardContent.addClass('loading');
    editableCardContent.removeClass('opened');
    editableCardContent.animate({
        height: '150px',
    }, 1500, 'swing', function () {
        let runDefault = true;
        if (response.data.completed_changed || response.data.start_from_changed) {
            editableCardContent.closest('.js-editable-card').fadeOut();
            runDefault = false;

            if (response.data.start_from_changed) {
                location.reload();
            }
        }

        if (response.data.user_changed) {
            refreshTaskCheckmark(editableCardContent);
            runDefault = true;
        }

        if (runDefault) {
            editableCardContent.parent().parent().load(options.showUrl);
        }

        let loadMoreSlicesButton = $('#js-load-more-slices');
        if (loadMoreSlicesButton.length > 0) {
            $('body').trigger('load-more-slices');
        }
    });
}

window.registerTaskCreateButton = (editableCardContent, options) => {
    $('.js-create-todo-from-company-item').on('click', function (e) {
        editableCardContent.addClass('loading');
        editableCardContent.html('');
        editableCardContent.animate({
            height: getInitialEditableCardHeight() + 'px',
        }, 1000, function () {
            editableCardContent.removeClass('loading');
        });

        editableCardContent.load($(this).data('create-route'), function () {
            handleTodoFromCompanyItem(editableCardContent, options);
        });
    });
}

window.handleTodoFromCompanyItem = (editableCardContent, options) => {
    const editableCardId = 'task_task-new';

    initAjaxForm('#js-content-container-task_task-new');

    $('.js-cancel-task-creation').on('click', function () {
        editableCardContent.addClass('loading');

        $(editableCardContent).animate({
            height: getInitialEditableCardHeight(),
        }, 1000, 'swing', function () {
            editableCardContent.parent().parent().load(options.showUrl, function () {
                $(editableCardContent).removeClass('loading');
                editableCardContent.removeClass('js-edit-mode');
            });
        });
    })

    reinitFields(editableCardId);

    initialSelect2Registration(editableCardContent, editableCardId);
    registerCompanySelect2(editableCardContent, editableCardId);
    registerPersonSelect2(editableCardContent, editableCardId);
    registerProjectSelect2(editableCardContent, editableCardId);

    registerDynamicHeight('#js-content-container-' + editableCardId);
    resizeEditCardOnTyping(editableCardContent, getInitialEditableCardHeight())
}

window.registerDeleteButton = (editableCardContent) => {
    editableCardContent.find('.js-editable-card-delete').on('click', function () {
        let content = $(editableCardContent);
        content.addClass('loading');
    });

    $(document).on('after-task-delete', function () {
        editableCardContent.animate({
                height: '150px',
            }, 1500, 'swing', function () {
                $(editableCardContent).closest('.js-editable-card').fadeOut();
                $(document).off('after-task-delete');
            });
    });

    $('body').on('click', '#confirmation-dialog-close-btn', function (e) {
        if (e.originalEvent !== undefined) {
            let content = $(editableCardContent);
            content.removeClass('loading');
        }
    });
}

window.registerCancelButton = (editableCardContent, options) => {
    editableCardContent.find('.js-editable-card-cancel').on('click', function () {
        editableCardContent.addClass('loading');
        editableCardContent.removeClass('opened');
        editableCardContent.animate({
            height: '150px',
        }, 1500, 'swing', function () {
            editableCardContent.parent().parent().load(options.showUrl);
        });
    });
}

window.reinitFields = (taskId) => {
    initSelect2(`#js-select2-task-category-container-${taskId}`)
    initSelect2(`#js-company-item-type-container-${taskId}`)
    initSelect2Ajax(`#js-select2-users-container-${taskId}`);
    initSelect2Ajax(`#js-select2-stakeholder-container-${taskId}`);
    initSelect2Ajax(`#js-select2-company-container-${taskId}`);
    initSelect2Ajax(`#js-select2-person-container-${taskId}`);
    initSelect2Ajax(`#js-select2-project-container-${taskId}`);
    initFlatpickr('.addItemGlobalContainer');
    initFlatpickr('.js-editable-card-content');
    initToolTip(`#js-select2-project-container-${taskId}`);
}

window.resizeEditCardOnTyping = (editCardContainer, initialHeight, animationDuration = 250) => {
    const calculateScrollHeight = () => {
        let scrollHeight = $(editCardContainer).find('[data-dynamic-height="true"]').prop('scrollHeight');
        return initialHeight + (
            scrollHeight <= 150
                ? 0
                : scrollHeight - 100
        );
    }

    const animateContainer = () => {
        editCardContainer.animate({
            height: calculateScrollHeight() + 'px',
        }, animationDuration);
    }

    $(editCardContainer).find('[data-dynamic-height="true"]').on('input', function () {
        animateContainer();
    })

    animateContainer();
}
