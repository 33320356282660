function dataLoadAjaxContent(elem) {
    // check if parameters should be added
    let url = new URL(elem.data('load-ajax-content'));
    if (elem.hasClass('js-ajax-content-with-parameters')) {
        const parameters = elem.data('parameter-fields') ?? [];
        _.forEach(parameters, function (parameterElemId) {
            const value = $(parameterElemId).val();
            const name = $(parameterElemId).attr('name');
            url.searchParams.append(name, value);

            $(parameterElemId).one('change', function (e) {
                e.preventDefault();
                $('[data-load-ajax-content]').trigger('needs-reload');
            });
        });
    }

    elem.addClass('loading')
    elem.removeClass('loaded');
    elem.load(url.href, function () {
        elem.removeClass('loading').addClass('loaded');

        let elemId = '#' + elem.attr('id');
        initSelect2Ajax(elemId);
        initSelect2(elemId);
        initAutonumeric(elemId);
        initDatatable(elemId);
        initInlineForm(elemId);
        initAjaxForm(elemId);
        initSortable(elemId);
        initChecklistItemLabel(elemId);
        initToolTip(elemId);
    });
}

window.registerDynamicHeight = (containerElement) => {
    $(containerElement + ' [data-dynamic-height="true"]').each(function () {
        this.setAttribute("style", "height:" + (this.scrollHeight === 0 ? 150 : this.scrollHeight) + "px;overflow-y:hidden;");
    }).on("input", function () {
        // Also add border width because scrollHeight doesn't include them - cd
        const borderTopWidth = parseInt($(this).css('border-top-width'));
        const borderBottomWidth = parseInt($(this).css('border-bottom-width'));
        const borderWidth = borderTopWidth + borderBottomWidth;

        this.style.height = (this.scrollHeight + borderWidth) + "px";
    });
}

window.parseToInt = (value) => {
    return value !== null
        ? parseInt(value)
        : null;
}

$(function () {
    $('[data-load-ajax-content]').each(function () {
        dataLoadAjaxContent($(this));
    })

    $('body').on('needs-reload', '[data-load-ajax-content]', function () {
        dataLoadAjaxContent($(this));
    });

    $('body').on('keyup', '.js-show-save-on-change input', function() {
        $(this).closest('.js-show-save-on-change').find('.save-btn').show();
        $(this).closest('.js-show-save-on-change').find('.save-btn').on('click', function () {
            $(this).hide();
        })
    });

    $('body').on('change', '[data-use-input-as-filter="true"]', function () {
        let target = $(this).data('filter-target');
        let val = $(this).val();

        $(target).children().each(function () {
            if (val == "" || $(this).data('filter-value') == val) {
                $(this).show();
            } else {
                $(this).hide();
            }
        })
    })

    // Source: https://stackoverflow.com/a/25621277
    registerDynamicHeight('body');
});

$(document).ready(function () {
    new ClipboardJS('.copy-to-clipboard');

    $('.dropdown-selection-arrow').on('click', function (e) {
        e.preventDefault();
        
        $(this).closest('.dropdown-selection').find('.dropdown-additional-items').toggleClass('d-none')
    })
})


