$(function(){
    $('#js-search-newsletter-forms').on('keyup', function () {
        let _this = $(this);
        $('#newsletter-form-list .newsletter-form-item-name').each(function () {
            let search = (_this.val());

            if (! search) {
                $(this).closest('.newsletter-form-list-item').show();
            } else {
                if($(this).html().includes(search)) {
                    $(this).closest('.newsletter-form-list-item').show();
                } else {
                    $(this).closest('.newsletter-form-list-item').hide();
                }
            }
        })
    })

    $('input[name="use_own_rc"]').on('change', function () {
        if ( $('input[name="use_own_rc"]:checked').val() == 1) {
            $("#own-rc-content").show();
        } else {
            $('#own-rc-content').hide();
        }
    })

    $('input[name="use_own_rc"]').trigger('change');

    $('.js-nl-set-visible-field').on('change', function () {
        let requiredField = $(this).closest('tr').find('.js-nl-set-required-field');
        if ($(this).is(':checked')) {
            requiredField.prop('disabled', false);
        } else {
            requiredField.prop('checked', false);
            requiredField.prop('disabled', true);
        }
    })

    let subscribersDataTableUrl = $('#js-ajax-newsletter-subscribers-table').data('ajax-url');

    let subscribersDataTable = $('#js-ajax-newsletter-subscribers-table').DataTable({
        dom: 'tr<"bottom boxanize-pagination"p>',

        processing: true,
        serverSide: true,
        ajax: {
            url: subscribersDataTableUrl,
        },
        columns: [
            {data: 'created_at'},
            {data: 'form'},
            {data: 'email'},
            {data: 'details'},
            {data: 'person_link'},
            {data: 'actions'},
        ],
        order: [[0,'desc']],
    });

    subscribersDataTable.withSearch($('#js-datatable-search'));

    $('body').on('change', '#d-link-person-form input[name="link_action"]', function () {
        let val = $('#d-link-person-form input[name="link_action"]:checked').val();

        if (val == 'new') {
            $('#js-d-link-action-btn').val($('#js-d-link-action-btn').data('string-new'));
            $('#d-link-person-form #existing-input-for-person').hide();
            $('#d-link-person-form #link-to-existing-person, #d-link-person-form #existing-input-for-person').hide();
        } else {
            $('#js-d-link-action-btn').val($('#js-d-link-action-btn').data('string-link'));
            $('#d-link-person-form #existing-input-for-person').show();
            $('#d-link-person-form #link-to-existing-person, #d-link-person-form #existing-input-for-person').show();
        }
    })
})
