window.initSelect2Ajax = function (containerElem) {
    $(containerElem + ' [data-use-select2-ajax]').each(function () {
        const withPreInitialization = $(this).data('pre-initialize') ?? false;
        if (withPreInitialization) {
            const route = $(this).data('url');
            if (route) {
                axios.get(route).then((response) => {
                    const results = response.data.results ?? [];
                    if (results.length === 0) {
                        $(this).prop('disabled', true);
                        $(this).closest('.row').find('[data-bs-toggle="tooltip"]').show();
                    } else {
                        $(this).prop('disabled', false);
                        $(this).closest('.row').find('[data-bs-toggle="tooltip"]').hide();
                    }
                });
            }
        }

        const withTagging = $(this).data('select2-with-tagging') ? true : null;
        const placeHolder = $(this).data('select2-placeholder') ? $(this).data('select2-placeholder') : 'wählen...';
        const width = $(this).data('select2-width') ? $(this).data('select2-width') : 'resolve';
        $(this).select2({
            // for some reason dropdownParent is needed because otherwise the search field isn't clickable
            // set containerElem as dropdownParent isn't a good idea because then the select2 dropdown
            // has the wrong position on mobile - let's use the parent element of our dropdown as parent
            dropdownParent: $(this).parent(),
            width: width,
            theme: 'bootstrap-5',
            tags: withTagging,
            placeholder: placeHolder,
            allowClear: true,
            language: "de",
            ajax: {
                url: $(this).data('url'),
                dataType: 'json',
            },
            templateResult: function (elem) {
                let text = elem.text;
                let styleClass = '';

                if (elem.hasWarning) {
                    styleClass = 'class="select2-has-warning"';
                }

                if (elem.prefix) {
                    return $('<span ' + styleClass  + '><span class="select2-custom-prefix">' + elem.prefix + ' </span>' + ' ' + elem.text + '</span>');
                }

                if (styleClass) {
                    return $('<span' + styleClass + '>' + text + '</span>')
                }

                return text;
            },
            templateSelection: function (elem) {
                let text = elem.text;

                /*let link = elem.link

                if (! link) {
                    link = $(elem.element).data('link');
                }*/

                // text = '<a class="js-select2-template-selection-link" href="' + link + '" target="_blank" style="margin-right: 5px;">' +
                //     '<i class="fa fa-external-link"></i></a>' + text;

                let styleClass = '';

                if (elem.hasWarning) {
                    styleClass = 'class="select2-has-warning"';
                }

                if (elem.prefix) {
                    return $('<span ' + styleClass  + '><span class="select2-custom-prefix">' + elem.prefix + ' </span>' + ' ' + elem.text + '</span>');
                }

                if ($(elem.element).data('prefix')) {
                    return $('<span ' + styleClass  + '><span class="select2-custom-prefix">' +
                        '<a target="_blank" href="' + $(elem.element).data('prefix') + '">' +
                        '<i class="fas fa-external-link"></i></a> </span>' + ' ' + elem.text + '</span>');
                }

                return $('<span ' + styleClass + '>' + text + '</span>')
            }
        });
    })
}

window.initSelect2 = function (containerElem) {
    $(containerElem + ' [data-use-select2="true"]').each((index, element) => {

        const selectElement = $(element);
        const hideSearchBox = selectElement.data('select2-hide-search');
        const isInvalid = selectElement.data('select2-is-invalid');
        const withTagging = selectElement.data('select2-with-tagging') ? true : null;
        const placeholder = selectElement.data('select2-placeholder') ? selectElement.data('select2-placeholder') : null;
        const allowClear = selectElement.data('select2-allow-clear') ?? false;

        const customTemplateResult = function (data, container) {

            if ($(data.element).data('has-vehicle')) {
                $(container).addClass('select2-custom-disabled');
            }

            return data.text;
        };

        selectElement.select2({
            // for some reason dropdownParent is needed because otherwise the search field isn't clickable
            // set containerElem as dropdownParent isn't a good idea because then the select2 dropdown
            // has the wrong position on mobile - let's use the parent element of our dropdown as parent
            dropdownParent: $(selectElement).parent(),
            width: '100%',
            theme: "bootstrap-5",
            tags: withTagging,
            allowClear: allowClear,
            placeholder: placeholder,
            containerCssClass: isInvalid ? 'select2-is-invalid' : null,
            minimumResultsForSearch: hideSearchBox ? Infinity : null,
            templateResult: selectElement.data('vehicle-select') ? customTemplateResult : function (data) {
                const selectElement = $(data.element);
                const countryCode = selectElement.data('countryCode');
                if (countryCode) {
                    return $('<span><span class="fi fi-' + countryCode + '"></span> ' + data.text + '</span>');
                }

                return data.text;
            },
            templateSelection: function (data) {
                const selectElement = $(data.element);
                const countryCode = selectElement.data('countryCode');
                const customCssClass = selectElement.data('cssClass');
                if (countryCode) {
                    return $('<span class="' + customCssClass + '"><span class="fi fi-' + countryCode + '"></span> ' + data.text + '</span>');
                }

                return $('<span class="' + customCssClass + '">' + data.text + '</span>');
            },
        });
    });
}

window.initSelect2Connect = function (containerElement) {
    $(containerElement + ' [data-select2-connect]').on('change', function () {

        let oldValue = $(this).data('oldValue');

        let connected = $(this).data('select2-connect');

        let id = '#' + $(this).attr('id');
        let value = $(this).val();

        connected.forEach(function (selectElement) {
            if (id === selectElement) {
                return;
            }
            $(selectElement + ' option[value="' + oldValue + '"]').prop('disabled', false);
        });

        connected.forEach(function (selectElement) {
            if (id === selectElement) {
                return;
            }

            if (value != null && value != '') {
                // alert(id + ' == ' + selectElement);
                $(selectElement + ' option[value="' + value + '"]').attr('disabled', true);
            }

            $(selectElement).trigger('change.select2');
        })
        $(this).data('old-value', $(this).val());
    });
}

$(document).ready(function() {
    initSelect2('body');
    initSelect2Ajax('body');
    initSelect2Connect('body');

    $('[data-select2-connect]').trigger('change');

    $('.select2-selection__rendered').on('mousedown', 'a', function (e) {
        e.stopPropagation();
    });
})

$(document).on('af.modal.shown', function () {
    initSelect2('#ajaxContentModal');
    initSelect2Ajax('#ajaxContentModal');
    initSelect2Connect('#ajaxContentModal');

    initSelect2('#ajaxContentModalNoPadding');
    initSelect2Ajax('#ajaxContentModalNoPadding');
    initSelect2Connect('#ajaxContentModalNoPadding');

    initSelect2('#global-info-container');
    initSelect2Ajax('#global-info-container');
    initSelect2Connect('#global-info-container');

    initSelect2('#js-detail-content');
});
