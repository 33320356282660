window.triggerBootstrapModal = function (elem, formData = null) {

    let $content_url = elem.data('content_url');
    let $header = elem.data('header') ?? '';
    let $trigger = elem.data('trigger') ?? '#ajaxContentModal';
    let $dialogPosition = elem.data('position') ?? 'center';
    let $dialogSize = elem.data('size') ?? 'sm';
    let $backdrop = elem.data('backdrop') ?? 'static';
    let $closeAfterFailure = elem.data('closeAfterFailure') ?? false;

    $($trigger).off('shown.bs.modal');

    let bsModal = new bootstrap.Modal($($trigger), {
        backdrop: $backdrop,
    })

    bsModal.show();

    switch ($dialogPosition) {
        case 'center':
            $($trigger + " .modal-dialog").addClass('modal-dialog-centered');
            break;
        case 'end':
            $($trigger + " .modal-dialog").addClass('modal-dialog-end')
            $('body').addClass('modal-open-end')
            break;
    }

    switch ($dialogSize) {
        case 'xxl':
        // no break
        case 'xl':
        // no break
        case 'lg':
        // no break
        case 'md':
        // no break
        case 'sm':
            $($trigger + " .modal-dialog").addClass('modal-' + $dialogSize);
            break;
    }

    if ($content_url) {
        axios.get(formData !== null ? $content_url + '?' + formData : $content_url)
            .then(function (response) {
                if (response.data.success !== undefined && response.data.success === false) {
                    location.reload();
                    return;
                }

                $($trigger + " .modal-body-placeholder").hide();
                $($trigger + " .modal-body-content").html(response.data).show();

                $($trigger + " .modal-title-placeholder").hide();
                $($trigger + " .modal-title-content").html($header).show();

                $(document).trigger('af.modal.shown');
            })

            .catch(function (error) {
                if (error.response.status === 422 && formData !== null) {
                    __defaultAxiosFailFunction(error);

                    if ($closeAfterFailure) {
                        $($trigger).on('shown.bs.modal', function () {
                            $($trigger).modal('hide');
                        });
                    }
                    return;
                }
            })
    }

}

window.triggerConfirmationModal = function (elem) {
    let $trigger = '#ajaxContentModalConfirmation'

    // open modal
    let confirmationModal = new bootstrap.Modal($($trigger), {
        backdrop: 'static',
    })

    confirmationModal.show();

    let action = elem.data('action') ?? null;
    let method = elem.data('method') ?? null;
    let warning = elem.data('warning') ?? null;
    let additional_warning = elem.data('additional-warning') ?? null;
    let needs_signing = elem.data('needs-signing') ?? null;
    let hidden_fields = elem.data('hidden-fields') ?? null;
    let ajax = elem.data('ajax') ?? null;
    let remove_after = elem.data('remove-after') ?? null;
    let trigger_reload = elem.data('trigger-reload') ?? null;
    let trigger_event = elem.data('trigger-event') ?? null;
    let submit_form_id = elem.data('submit-form-id') ?? null

    let data = {
        action, method, warning, needs_signing, hidden_fields, ajax, remove_after, trigger_reload, trigger_event,
        submit_form_id, additional_warning
    }

    axios.get('/dialoge/konfirmation', { params: data })
        .then(function (response) {
            $($trigger + " .modal-body-placeholder").hide();
            $($trigger + " .modal-body-content").html(response.data).show();

            $($trigger + " .modal-title-placeholder").hide();
            $($trigger + " .modal-title-content").html('Aktion bestätigen').show();
        })

        .catch(function (error) {
            if (error.response.status === 422) {
                alert('Es ist ein Fehler beim Laden des Inhalts aufgetreten');
            }
        })
}

$(document).ready(function() {
    $('body').on('click', '.js-trigger-modal', function (e) {
        e.preventDefault();
        e.stopPropagation();

        window.triggerBootstrapModal($(this));
    });

    $('#ajaxContentModalNoHeader, #ajaxContentModal, #ajaxContentModalConfirmation').on('hidden.bs.modal', function() {
        if (window.shouldReloadAfterModalClose) {
            window.location.reload();
            return;
        }


        let $id = '#' + $(this).attr('id');
        $($id + ' .modal-body-placeholder').show();
        $($id + ' .modal-body-content').html('').hide();
        $($id + ' .modal-title-placeholder').show();
        $($id + ' .modal-title-content').html('').hide();
        $($id + ' .modal-dialog').removeClass('modal-xl').removeClass('modal-dialog-end');
        $('body').removeClass('modal-open-end');
    });

    $('body').on('click', '.js-needs-confirmation', function (e) {
        e.preventDefault();

        window.triggerConfirmationModal($(this));
    })

    $('body').on('submit', '#js-dialog-global-confirmation-form', function(e) {
        if ($(this).data('needs-confirmation')) {
            let signingField = $(this).find('#d_signing_text');
            if (! signingField || signingField.val() !== 'OK') {
                e.preventDefault();
                $(this).find('#d_signing_error').show();
                return;
            }
        }

        if ($(this).data('ajax')) {
            e.preventDefault();

            let triggerReload = $(this).data('trigger-reload');
            let triggerEvent = $(this).data('trigger-event');

            axios.post($(this).attr('action'), $(this).serialize()).then(function () {

                $.toast({
                    icon: 'success',
                    text: 'Die Aktion wurde durchgeführt',
                })

                if (triggerReload) {
                    $(document).trigger('af.modal.shown');
                    $('[data-load-ajax-content]').trigger('needs-reload');
                }

                if (triggerEvent) {
                    $(document).trigger(triggerEvent);
                }

            }).catch(function () {
                $.toast({
                    icon: 'error',
                    text: 'Die Aktion konnte nicht durchgeführt werden',
                })
            })

            $('#confirmation-dialog-close-btn').trigger('click');
        }
    })
})

// default dialog fail function
window.__defaultDialogJqueryAjaxFailFunction = function(jqXHR) {
    if (jqXHR.status == 422) {
        var text = '';

        $.each(jqXHR.responseJSON.errors, function(key, error) {
            text += "<p>"+error+"</p>";
        })
        $.toast({
            heading: "Achtung",
            text: text,
            icon: 'error'
        })
    } else {
        $.toast({
            icon: 'error',
            text: 'Die Aktion konnte nicht ausgeführt werden.'
        })
    }
}

window.__defaultAxiosFailFunction = function (error) {
    if (error.response && error.response.status === 422) {
        let text = '';
        $.each(error.response.data.errors, function (name, messages) {
            text += messages[0] + "<br>";
        })

        $.toast({
            icon: 'error',
            text: text,
        })
    } else {
        alert(error.message);
    }
}
