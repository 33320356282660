$(function () {
    const initColorpicker = (containerElement) =>
        $(containerElement + ' #js-color-picker-container').each(function (index, element) {
            $(element).colorpicker({
                popover: false,
                inline: true,
                container: '#js-color-picker-container',
                format: 'hex',
            });
        })

    initColorpicker('body');
    $(document).on('af.modal.shown', function () {
        initColorpicker('#ajaxContentModal');
    })
})
