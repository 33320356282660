window.initInlineForm = (containerElement) => {
    $(containerElement + ' [data-inline-form]').each(function (index, element) {
        const event = $(element).data('inline-form') !== '' ? $(element).data('inline-form') : 'click';

        if (event === 'blur') {
            $(element).on('keypress', function (e) {
                if (e.keyCode === 13) {
                    $(element).blur();
                }
            });
        }

        $(element).on(event, function (e) {
            e.preventDefault();
            const value = $(this).parent().find('input').val();
            const url = $(this).data('route');
            const method = $(this).data('method');

            if (value !== "") {
                axios.request({
                    url,
                    method,
                    data: { value },
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then((response) => {
                    if (response.data.success) {
                        $('[data-load-ajax-content]').trigger('needs-reload');

                        if ($(this).data('clear-input')) {
                            $(this).val('');
                        }

                        if ($(this).data('trigger-event-after-submit') && $(this).data('trigger-event-after-submit') !== "") {
                            $('body').trigger($(this).data('trigger-event-after-submit'));
                        }
                    }
                }).catch(__defaultAxiosFailFunction);
            }
        })
    })
}

window.initAjaxForm = (containerElement) => {
    $(containerElement + ' .js-submit-ajax-form').on('submit', function (e) {
        e.preventDefault();

        let form = $(this);
        // console.log(form.serialize());

        let url = form.attr('action');
        let data = {};

        if ($(this).data('use-default-serialization') !== undefined) {
            data = form.serialize();
        } else {
            $(this).find('input, select, textarea').each(function (key, item) {
                if ($(this).is(':disabled')) {
                    return;
                }

                let name = $(this).attr('name');
                let autonumericElement;
                if (autonumericElement = AutoNumeric.getAutoNumericElement($(this)[0])) {
                    data[name] = autonumericElement.getNumericString();
                } else {
                    if (name && name.endsWith('[]')) {
                        if ($(this).is(':checked')) {
                            name = name.substring(0, name.length - 2);

                            if (data[name]) {
                                data[name].push($(this).val());
                            } else {
                                data[name] = [$(this).val()];
                            }
                        }

                        if ($(this).data('select2')) {
                            data[name.replace("[]", "")] = [];
                            $('[name="' + name + '"]').each(function () {
                                if ($(this).select2('data').length > 0) {
                                    data[name.replace("[]", "")].push($(this).select2('data')[0].element.value);
                                }
                            })
                        }
                    } else {
                        if ($(this).attr('type') === 'radio' || $(this).attr('type') === 'checkbox') {
                            data[name] = $(this).closest('form').find('[name="' + name + '"]:checked').val();
                        } else {
                            data[name] = $(this).val();
                        }
                    }
                }
            })
        }

        axios.post(url, data)
            .then(function(response) {

                // update initial values
                form.find("[data-initial-value]").each(function() {
                    let autonumericElement;
                    if (autonumericElement = AutoNumeric.getAutoNumericElement($(this)[0])) {
                        $(this).data('initial-value', autonumericElement.getNumericString());
                    } else {
                        $(this).data('initial-value', $(this).val());
                    }
                })

                if (form.data('action-target')) {
                    $(form.data('action-target')).hide();
                }

                if (response.data.success) {
                    if (response.data.redirectUrl) {
                        location.href = response.data.redirectUrl;
                        return;
                    }

                    if (form.data('reload-after-submit') === "") {
                        location.reload();
                        return;
                    }

                    $.toast({
                        icon: 'success',
                        text: response.data.success
                    });
                } else {
                    if (response.data.message) {
                        $.toast({
                            icon: 'error',
                            text: response.data.message
                        })
                    } else {
                        // Wieso zeigen wir eine Erfolgsmeldung, wenn response.data.success false ist?
                        $.toast({
                            icon: 'success',
                            text: 'Die Aktion wurde erfolgreich ausgeführt'
                        })
                    }
                }

                if(form.data('close-modal')) {
                    $('#ajaxContentModal').modal('hide');
                }

                if (form.data('trigger-event-after-submit')) {
                    if (form.data('trigger-event-after-submit') === 'needs-reload') {
                        $(document).trigger('af.modal.shown');
                        $('[data-load-ajax-content]').trigger('needs-reload');
                    } else {
                        if (/needs-reload/.test(form.data('trigger-event-after-submit'))) {
                            $('[data-load-ajax-content]').trigger('needs-reload');
                        }
                        $(window).triggerAll(form.data('trigger-event-after-submit'), response.data);
                    }
                }
            }).catch ((error) => {
                if (form.data('trigger-event-after-failure')) {
                    $(window).triggerAll(form.data('trigger-event-after-failure'));
                }
                window.__defaultAxiosFailFunction(error);
            });
    })
}

$(function () {
    initInlineForm('body');
    initAjaxForm('body');
})

$(document).on('af.modal.shown', function () {
    initInlineForm('#ajaxContentModal');
    initAjaxForm('#ajaxContentModal');
    initInlineForm('#ajaxContentModalNoPadding');
    initAjaxForm('#ajaxContentModalNoPadding');
});
