$(function () {
    $('body').on('after-load-more-slices', function () {
        $('.js-company-item-card').each(function() {
            let companyItemCardContainer = $(this).closest('.js-company-item-card-container');
            const showUrl = companyItemCardContainer.data('showUrl');

            $(this).load(showUrl);
        });
    });
})
