import registerUserAutocomplete from "./user-autocomplete";

$(function () {
    $(window).on('after-load-editable-card', function () {
        $('.js-task-description-edit').on('click', function () {
            $(this).hide();
            $(this).parent().find('.item_task_description').removeClass('d-none');

            $(this).closest('.js-editable-card-content').animate({
                height: '100%',
            }, 1000, function () {});
        });

        registerUserAutocomplete($('.item_task_description'));
    });
})
