window.initChecklistItemLabel = (container) => {
    $(container + ' .js-checklistItem-label').each(function (index, element) {
        $(element).on('click', function () {
            const inputId = $(this).data('for');
            $(this).fadeOut(function () {
                $('#' + inputId).prop('hidden', false);
            });
        })
    });
}

$(function () {
    initChecklistItemLabel('body');
});

$(document).on('af.modal.shown', function () {
    initChecklistItemLabel('#ajaxContentModal');
});
