window.initAutonumeric = function (containerElem) {
    $( containerElem + ' [data-use-autonumeric="true"]').each((index, element) => {

        let unformatOnSubmit = true;

        if ($(element).data('autonumeric-no-unformat') !== undefined) {
            unformatOnSubmit = false;
        }

        let autoNumericOptions = {
            digitGroupSeparator: $(element).data('group-separator') ?? '.',
            decimalCharacter: ',',
            decimalCharacterAlternative: '.',
            unformatOnSubmit: unformatOnSubmit,
            unformatOnHover: true,
            modifyValueOnWheel: false,
        };

        if ($(element).data('onlyInteger') !== undefined) {
            autoNumericOptions = { ...autoNumericOptions, decimalPlaces: 0 };
        }

        let min = $(element).data('min');
        if (min !== undefined) {
            autoNumericOptions.minimumValue = min;
        }

        let max = $(element).data('max');
        if (max != undefined) {
            autoNumericOptions.maximumValue = max;
        }

        let leadingZero = $(element).data('leading-zero');
        if (leadingZero != undefined) {
            autoNumericOptions.leadingZero = 'keep';
        }

        new AutoNumeric(element, autoNumericOptions);
    });
}

$(document).ready(function() {
    initAutonumeric('body');
});

$(document).on('af.modal.shown', function () {
    initAutonumeric('#ajaxContentModal');
});
