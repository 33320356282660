window.refreshTaskCheckmark = (cardTaskElement) => {
    const showCheckmarkRoute = $(cardTaskElement).closest('.js-editable-card').data('showCheckmarkRoute');
    if (showCheckmarkRoute) {
        $(cardTaskElement).closest('.js-editable-card')
            .find('.js-task-checkmark')
            .load(showCheckmarkRoute);
    }
}

window.handleTaskCheckClick = (element) => {
    let $itemContainer = element.closest('.js-editable-card');

    if (element.data('route')) {
        let that = element;
        element.parent().addClass('loading');
        axios.patch(element.data('route'))
            .then(function(response) {
                const responseData = response.data;
                let originalElement = $($itemContainer);
                $($itemContainer).fadeOut(1000, function() {
                    $(this).remove();
                    if (responseData.new_state === 1) {
                        $('#companyItemsContainer').prepend(originalElement);
                        originalElement.find('.task-container').addClass('task-container--completed');
                        originalElement.find('.task-check').addClass('task-check--checked');
                        originalElement.fadeIn(1000);
                    } else {
                        $('#js-company-item-list').prepend(originalElement);
                        originalElement.find('.task-container').removeClass('task-container--completed');
                        originalElement.find('.task-check').removeClass('task-check--checked');
                        originalElement.fadeIn(1000);
                    }
                    that.parent().removeClass('loading');
                    // $('body').trigger('load-more-slices');
                    // $(window).trigger('task-reload');
                });
            });
    }
}

$(function () {
    let bodyElement = $('body');

    bodyElement.on('click', '.task-check', function() {
        handleTaskCheckClick($(this));
    });

    // Expander is for showing content of a company item if task is connected to one
    bodyElement.on('click', '.js-expander', function(e) {
        e.preventDefault();

        $(this).closest('.js-task-expandable').find('.js-expandable-content').toggleClass('d-none');
        $(this).find('.fa').toggleClass('fa-caret-right').toggleClass('fa-caret-down');
    });
});
